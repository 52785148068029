/* eslint-disable react/no-danger */
import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "components/Layout/Layout"
import "./markdownPage.scss"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <div className="content">
        <h3>{frontmatter.title}</h3>
        <div className="" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  )
}

Template.propTypes = {
  data: PropTypes.shape.isRequired,
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
